/* eslint-disable */
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { ModalHelper } from './function/fixThrough';
import HomeAPI from './api/index';
import IfxComponent from './components/ifx/index';
import HousePriceComponent from './components/housePrice/index';
import SaleItemComponent from './components/saleItem/index.vue';
import PromotionRecommend from './components/saleItem/promotionRecommend';
import HotQuestionComponent from './components/hotQuestion/index';
import SeoFooterComponent from './components/seo/seoFooter';
import PermissionTipComponent from './components/permissionTip/index.vue';
import { buildURL } from './function/buildURL';
import { printLog } from './function/log';
import { throttle } from './function/functions';
import appdown from 'oldCommon/mixins/DownloadAndOpenApp';
import EsfItem from './components/recommend/item.vue';
import 'swiper/dist/css/swiper.css';
import { isEmpty } from '~/utils/common';
import { initTrack } from '~/utils/track';
import {
    BizAppDownloadBar,
    BizOpenInApp,
    BizHeader,
    BizFooter,
    BizXfLoupanItem,
    BizXfThemepack
} from '@ajk/touch-vue-components';
import createHomeModule from './store/index';
const { moduleName, serverApi } = createHomeModule();
/* eslint-enable */

const pagename = 'Anjuke_Home';
/*let province = ''// 省份
let cityName = ''// 城市中文
let coord = ''// 经纬度
let pc_url = ''*/

export default {
    inject: ['ENV'],
    mixins: [appdown],
    sendPv() {
        return {
            pageName: 'Anjuke_Home'
        };
    },
    asyncData({ route, store, redirect }) {
        printLog(pagename, 3, route);
        printLog(pagename, 3, store.state.request);
        let cityName = _.get(store.getters.getRequest, 'cityName');
        let origin = _.get(store.getters.getRequest, 'origin');
        const feCommon = store.getters.getFeCommon;
        const app_ip = feCommon.clientIP;
        const city = route.params.city;
        store.commit(
            `${moduleName}/SET_SEO_DESCRIPTION`,
            `安居客${cityName}房产网，为您提供${cityName}房产中介公司信息，${cityName}二手房出售、出租信息， ${cityName}新房楼盘信息。另有资讯、问答、海外地产等频道，让您找房更轻松。`
        );
        const city_id = store.getters.getRequest.cityId;
        const serverFetch = [];
        _.forEach(serverApi, (value, key) => {
            serverFetch.push(store.dispatch(`${moduleName}/GET_STATE_DATA`, { key, params: { city_id, app_ip } }));
        });

        return new Promise((resolve, reject) => {
            Promise.all(serverFetch)
                .then(() => {
                    printLog(pagename, 3, 'resolve');
                //     const is_redirct_xf = _.get(store.state[moduleName], 'baseView.is_redirct_xf');
                //     /*  province = _.get(store.state[moduleName], 'baseView.province')
                // coord = _.get(store.state[moduleName], 'baseView.coord')
                // pc_url = _.get(store.state[moduleName], 'baseView.pc_url')*/

                //     if (is_redirct_xf) {
                //         let { query } = route || {};
                //         let queryArr = [];
                //         if (_.isPlainObject(query)) {
                //             _.forEach(query, (v, k) => {
                //                 queryArr.push(`${k}=${v}`);
                //             });
                //         }
                //         // return redirect(302, origin + '/404');

                //         return redirect(
                //             302,
                //             origin + `/${city}/xinfang/${queryArr.length ? '?' + queryArr.join('&') : ''}`
                //         );
                //         /*reject({
                //             error: 302,
                //             url: `/${city}/xinfang/${queryArr.length ? '?' + queryArr.join('&') : ''}`
                //         });*/
                //     }
                    resolve();
                })
                .catch(error => {
                    printLog(pagename, 3, 'reject', error);
                    return redirect(302, origin + '/404');
                    /*reject({
                        error: 404
                    });*/
                });
        });
        // return Promise.resolve()
    },
    head() {
        const cityName = _.get(this.$store.getters.getRequest, 'cityName');
        const title = `${cityName}房产网, ${cityName}房地产信息网，${cityName}二手房,新房，租房-${cityName}安居客`;
        const meta = [];
        let province = _.get(this.$store.state[moduleName], 'baseView.province');
        let coord = _.get(this.$store.state[moduleName], 'baseView.coord');
        let pc_url = _.get(this.$store.state[moduleName], 'baseView.pc_url');
        meta.push({
            hid: 'applicable-device',
            name: 'applicable-device',
            content: 'mobile'
        });
        meta.push({
            hid: 'keywords',
            name: 'keywords',
            content: `${cityName}房产网，${cityName}房产信息网，${cityName}二手房，${cityName}新房网`
        });
        meta.push({
            hid: 'description',
            name: 'description',
            content: `安居客${cityName}房产网,提供${cityName}二手房、新房、租房、房价行情走势,解答找房用户困惑, ${cityName}房地产信息网租售服务平台,为您提供满意的找房体验。`
        });
        meta.push({
            hid: 'location',
            name: 'location',
            content: `province=${province};city=${cityName};coord=${coord}`
        });
        return {
            title,
            meta,
            link: [
                {
                    rel: 'canonical',
                    href: `${pc_url}`
                }
            ]
        };
    },
    /* title() {
        return `${cityName}房产网, ${cityName}房地产信息网，${cityName}二手房,新房，租房-${cityName}安居客`;
    },
    meta() {
        //            <link rel="canonical" href="https:${config.clientApiHost + siteUrl_str}">
        //            <meta name="format-detection" content="telephone=no" />
        let cityName = _.get(this.$store.getters.getRequest, 'cityName');
        let province = _.get(this.$store.state[moduleName], 'baseView.province');
        let coord = _.get(this.$store.state[moduleName], 'baseView.coord');
        let pc_url = _.get(this.$store.state[moduleName], 'baseView.pc_url');
        return `
            <meta name="applicable-device" content="mobile">
            <meta name="keywords" content="${cityName}房产网，${cityName}房产信息网，${cityName}二手房，${cityName}新房网">
            <meta name="description" content="安居客${cityName}房产网,提供${cityName}二手房、新房、租房、房价行情走势,解答找房用户困惑, ${cityName}房地产信息网租售服务平台,为您提供满意的找房体验。">
            <meta name="location" content="province=${province};city=${cityName};coord=${coord}">
            <link rel="canonical" href="${pc_url}">
        `;
    }, */
    components: {
        BizAppDownloadBar,
        BizOpenInApp,
        BizHeader,
        BizFooter,
        BizXfLoupanItem,
        BizXfThemepack,
        IfxComponent,
        HousePriceComponent,
        SaleItemComponent,
        PromotionRecommend,
        HotQuestionComponent,
        SeoFooterComponent,
        PermissionTipComponent,
        EsfItem
    },
    /* meta(){
      // return `<script>alert(1)</script>`
    }, */
    data() {
        return {
            // showXinChunRed,
            isMounted: false,
            pagename,
            city: '',
            showAppInOpen: false,
            city_id: '',
            cityName: '',
            defaultImage: '//pages.anjukestatic.com/usersite/touch/img/comm/nopic_list3.png',
            ifxStyle: 'padding:20px 0px',
            swiperOption: {
                direction: 'vertical',
                initialSlide: 0,
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true
                }
            },
            guessLike: null, // 猜你喜欢
            isShowSearchPage: false, // 是否显示搜索页面,
            isFirstInSearchPage: false,
            searchInput: null,
            searchPlaceholder: {
                ESF: '请输入小区名或地址',
                XF: '请输入楼盘名称或地址'
            },
            isShowSearchClassify: false,
            searchClassifyKey: 'ESF',
            searchClassify: {
                ESF: '二手房',
                XF: '新房'
            },
            searchResult: {
                classify: '',
                list: null,
                brand: null
            },
            searchHistory: null,
            searchHotTags: {},
            searchSuggestRequest: null,
            searchKey: '', // 搜索关键字
            tip: null, // 顶部下载位app信息,
            isTipShow: false // app信息里面的隐私政策
        };
    },
    watch: {
        isShowSearchPage(newVal, oldVal) {
            if (!this.isFirstInSearchPage) {
                this.$soj.trackEvent(this.pagename, { exposure: { Exp_home_search_baoguang: [1] } }, 3);
                this.searchInputFocus();
                this.isFirstInSearchPage = true;
            }
            if (newVal) {
                ModalHelper.afterOpen();
            } else {
                ModalHelper.beforeClose();
                this.isShowSearchClassify = false;
            }
        },
        searchClassifyKey(newVal, oldVal) {
            this.clearSarchResult();
            this.searchInputFocus();
            this.setSearchPageView();
            this.getSuggest();
        },
        searchKey(newVal, oldVal) {
            this.getSuggest();
        }
    },
    filters: {
        transHttpToHttps(url) {
            if (url) {
                return url.replace(/^http:\/\//gi, 'https://');
            }
            return 'javascript:void(0)';
        }
    },
    created() {
        this.cityName = _.get(this.$store.getters.getRequest, 'cityName');
    },
    beforeMount() {
        if (typeof window !== 'undefined') {
            const start = 1585927800000;
            const end = 1586016000000;
            const now = new Date().getTime();
            if (now >= start && now < end) {
                document.documentElement.className += ' specialGray';
            }
        }
    },
    mounted() {
        this.printLog(3, '-----mounted----', this);
        initTrack({pageName: 'Anjuke_Home', plat: 2, cp: {}}, { url: ''});
        this.cityName = _.get(this.$store.getters.getRequest, 'cityName');
        this.city_id = _.get(this.$store.getters.getRequest, 'cityId');
        this.city = this.$route.params.city;
        Object.keys(serverApi).map(value => {
            this.printLog(3, value, this[value]);
        });
        this.fetchAppTip();
        this.searchInput = $('#search-text');
        this.addEvent();
        $('.OpenInAppBarWrap').css('display', 'block !important');
        $('.open-btn').css('display', 'block !important');
        this.$nextTick(() => {
            setTimeout(() => {
                this.isMounted = true;
            }, 1000);
            this.setSearchClassify();
            this.getClientData();
            this.selSearchHistory();
        });
        // 引导打开小程序
        this.openWebMiniApp()
    },

    computed: {
        ...mapGetters({
            request: 'getRequest'
        }),
        ...mapGetters(moduleName, Object.keys(serverApi)),

        isGraySetting(){
            let is_gray_setting = _.get(this.get_ashen_switch,'is_open')
            if(!!is_gray_setting){
                return true
            }else{
                return false
            }
        },
        sourceSoj() {
            return _.get(this.request, 'source_h5.app', '');
        },
        systemSoj() {
            return _.get(this.request, 'source_h5.os', '');
        },
        versionSoj() {
            return _.get(this.request, 'source_h5.ver', '');
        },
        tw_url() {
            let tu = _.get(this.baseView, 'tw_url');
            if (tu) {
                return tu.replace(/^http:\/\//gi, 'https://');
            }
            return '';
        },
        menuSwiper() {
            return this.$refs.menuSwiper.swiper;
        },
        // 二手房推荐
        esfRecommend() {
            const data = this.esf_recommend;
            if (_.isArray(data.list) && data.list.length) {
                /* data.forEach(v => {
                   v.exposure = {'Exp_home_esf_rec_area': 1}
               }) */
                return data.list;
            }
            return null;
        },
        // 新房主题包
        xfThemeList() {
            const data = this.xf_themeList;
            if (_.isPlainObject(data) && _.isArray(data.theme_packs) && data.theme_packs.length) {
                return data.theme_packs;
            }
            return null;
        },
        // 新房推荐列表
        xfRecommend() {
            const data = this.xf_recommend;
            if (_.isPlainObject(data) && _.isArray(data.rows) && data.rows.length) {
                return data.rows.slice(0, 5);
            }
            return null;
        },
        // 热门回答列表
        hotQuestionsList() {
            const data = this.hot_questionsList;
            if (_.isPlainObject(data) && _.isArray(data.list) && data.list.length) {
                const fin = data.list.slice(0, 10);
                fin.forEach(v => {
                    v.tagFromSoj = 'home_qa_hotqa';
                    v.qaFromSoj = 'home_qa_hotqa';
                });
                return fin;
            }
            return null;
        },
        isSeo() {
            const isSeo = !!+_.get(this.baseView, 'seo_source_type') || +_.get(this.request, 'seo_source_type', false);
            return isSeo;
            /* if (isSeo) {
                return true;
            }
            return !this.isMounted; */
            // || (_.get(this.baseView, 'is_abtest') === 1);
        },
        cityInfo() {
            return this.cityName ? { name: this.cityName, url: '//m.anjuke.com/cityList' } : {};
        },
        userType() {
            return _.get(this.baseView, 'user_type') === 'esf';
        },
        showXinChunRed() {
            const data = _.get(this.get_home_m_base_data, 'activity');
            return _.isObject(data) && Object.keys(data).length && data.id;
        },
        menuList() {
            const arr = _.get(this.get_home_m_base_data, 'top_menu');
            let fromSoj = 'homedbdh'
            let list = null;
            if (_.isArray(arr) && arr.length) {
                list = arr.slice(0);
            } else {
                list = [];
            }
            // let domain= location.origin || (location.protocol + "//" + location.hostname + (location.port ? ':' + location.port: ''))
            const isLogin = _.get(this.baseView, 'user_id');
            const url = _.get(this.baseView, 'member_url');
            const user = {
                fromSoj,
                title: isLogin ? '我的主页' : '登录/注册',
                type: 'dlzc',
                url
            };
            list.push(user);
            return list;
        },
        navList() {
            const arr = _.get(this.baseView, 'top_menu');
            let list = null;
            // let fromSoj = 'homedbdh'
            if (_.isArray(arr) && arr.length) {
                list = arr.slice(0, 3);
            }
            return list
                ? list.map(v => {
                      v.name = v.title;
                      // v.fromSoj = fromSoj
                      return v;
                  })
                : null;
        },

        // 登录 type=dlzc
        slideList() {
            const arr = _.get(this.baseView, 'top_menu');
            // let fromSoj = 'homedbdh'
            let list = null;
            if (_.isArray(arr) && arr.length) {
                list = arr.slice(3);
            } else {
                list = [];
            }
            if (list && list.length) {
                list.map(v => {
                    v.name = v.title;
                    // v.fromSoj = fromSoj
                    // v.icon = 'icon-ec08'
                    return v;
                });
            }
            // let domain= location.origin || (location.protocol + "//" + location.hostname + (location.port ? ':' + location.port: ''))
            const isLogin = _.get(this.baseView, 'user_id');
            const url = _.get(this.baseView, 'member_url');
            const user = {
                // fromSoj,
                name: isLogin ? '我的主页' : '登录/注册',
                type: 'dlzc',
                url
            };
            list.push(user);
            return list;
        },

        // 主导航信息moduleName
        mainMenu() {
            const from = 'anjuke_home';
            const menu = _.get(this.baseView, 'menu');
            if (_.isArray(menu) && menu.length) {
                let res = menu.map(v => {
                    v.className = `bg-sy_pd_icon_${v.type}`;
                    v.from = from;
                    return v;
                });
                if (res.length % 5) {
                    const cz = 5 - (res.length % 5);
                    res = res.concat(new Array(cz).fill({}));
                }
                return res.slice(0, 10);
            }
            return null;
        },
        // 主副金刚位
        getMenu() {
            const from = 'anjuke_home';
            const menu = _.get(this.get_home_m_base_data, 'icons');
            if (_.isArray(menu) && menu.length) {
                let res = menu.map(v => {
                    // v.className = `bg-sy_pd_icon_${v.key}`;
                    v.from = from;
                    return v;
                });
                return res;
            }
            return null;
        },
        // 头条
        toutiao() {
            const tt = _.get(this.get_home_m_base_data, 'head_line.couple');
            if (_.isArray(tt) && tt.length) {
                return tt;
            }
            return null;
        },
        // 工具栏
        tool() {
            const from = 'anjuke_home';
            const tools = _.get(this.baseView, 'tool');
            if (_.isArray(tools) && tools.length) {
                return tools.map(v => {
                    v.className = `bg-sy_xgj_icon_${v.type}`;
                    v.from = from;
                    return v;
                });
            }
            return null;
        },
        // 房价
        homeCityprice() {
            const data = _.get(this.get_home_m_base_data, 'card_list');
            const houseprice = data && data[0];
            if (_.isObject(houseprice) && Object.keys(houseprice).length) {
                return {
                    ...houseprice,
                    exposure: { Exp_home_price_area: 1 },
                    from: 'anjuke_home_city_price'
                };
            }
            return null;
        },
        promotionsBasic() {
            const month = new Date().getMonth() + 1;
            return {
                month,
                cityName: this.cityName
            };
        },
        promotionsEsf() {
            return {
                pm: 'b1118',
                clickSoj: 'track_home_esfrec_banner_download',
                ...this.promotionsBasic
            };
        },
        promotionsXf() {
            return {
                pm: 'b1114',
                clickSoj: 'track_home_xfrec_banner_download',
                ...this.promotionsBasic
            };
        },
        promotionsGuess() {
            return {
                pm: 'b1446',
                clickSoj: 'track_home_cai_list',
                ...this.promotionsBasic
            };
        },

        promotionsHouse1() {
            const month = new Date().getMonth() + 1;
            return {
                month,
                cityName: this.cityName
            };
        },
        esfSaleListUrl() {
            return buildURL({
                domain: this.tw_url,
                city: this.tw_url ? '' : this.city,
                page: 'saleList'
            });
        },
        xfListUrl() {
            return buildURL({
                domain: this.tw_url,
                city: this.tw_url ? '' : this.city,
                page: 'xfList'
            });
        },
        qalistUrl() {
            return buildURL({
                domain: this.tw_url,
                city: this.tw_url ? '' : this.city,
                page: 'qalist'
            });
        },
        // 历史记录
        historyList() {
            const cid = this.city_id;
            const sk = this.searchClassifyKey;
            const sh = this.searchHistory;
            let arr = null;
            if (sh && sh[sk] && _.isArray(sh[sk])) {
                _.forEach(sh[sk], (v, k) => {
                    if (_.get(v, 'city_id') === cid && _.isArray(v.list)) {
                        arr = v.list;
                        return false;
                    }
                });
            }
            return arr;
        },
        recommend_loupans() {
            const loupan = _.get(this.searchHotTags[this.searchClassifyKey], 'recommend_loupans');
            if (_.isArray(loupan) && Object.keys(loupan).length > 0) {
                return loupan;
            }
            return null;
        },
        hot_brand() {
            const brand = _.get(this.searchHotTags[this.searchClassifyKey], 'brand');
            if (_.isArray(brand) && Object.keys(brand).length > 0) {
                return brand;
            }
            return null;
        },
        hot_loupans() {
            const loupan = _.get(this.searchHotTags[this.searchClassifyKey], 'hot_loupans');
            if (_.isArray(loupan) && Object.keys(loupan).length > 0) {
                return loupan;
            }
            return null;
        },
        feature_theme() {
            const fe = _.get(this.searchHotTags[this.searchClassifyKey], 'feature_theme');
            if (_.isArray(fe) && Object.keys(fe).length > 0) {
                return fe;
            }
            return null;
        },
        seo_description() {
            return _.get(this.$store.state[moduleName], 'seoDescription');
        },
        isShowSearchHot() {
            // searchHotTags&&searchHotTags[searchClassifyKey]
            let res = false;
            if (this.searchHotTags) {
                switch (this.searchClassifyKey) {
                    case 'ESF':
                        if (
                            _.isArray(this.searchHotTags[this.searchClassifyKey]) &&
                            this.searchHotTags[this.searchClassifyKey].length
                        ) {
                            res = true;
                        }
                        break;
                    case 'XF':
                        if (this.recommend_loupans || this.hot_brand || this.feature_theme) {
                            res = true;
                        }
                        break;
                    default:
                        res = false;
                }
            }
            return res;
        },
        /* 最底部菜单 */
        footerNavList() {
            const city = this.city;
            const city_id = this.city_id;
            let download_url = 'https://itunes.apple.com/cn/app/ju-ke-er-shou-fang-fang-jia/id415606289/?mt=8';
            if (typeof window !== 'undefined') {
                const versions = _.get(window.browser, 'versions');
                if (versions.android) {
                    download_url = 'https://android.anjuke.com/download/anjuke/b190';
                }
            }
            if (city && city_id) {
                return [
                    {
                        url: `https://m.anjuke.com/${city}/xiaoqu/`,
                        title: '小区大全',
                        fromSoj: ''
                    },
                    {
                        url: `https://m.anjuke.com/${city}/sitemap/`,
                        title: '网站地图',
                        fromSoj: ''
                    },
                    {
                        url: `https://m.anjuke.com/${city}/propsitemap/esf/`,
                        title: '最新房源',
                        fromSoj: ''
                    },
                    {
                        url: `https://m.anjuke.com/${city}/caculator/`,
                        title: '房贷计算器',
                        fromSoj: 'tw_footer'
                        // ref: 'nofollow',
                    },
                    {
                        url: `https://m.anjuke.com/assess/form/?city_id=${city_id}`,
                        title: '估价',
                        fromSoj: 'tw_dbdh_gujia',
                        ref: 'nofollow'
                    },
                    {
                        url: 'javascript:void(0)',
                        title: '手机版',
                        fromSoj: '',
                        ref: 'nofollow'
                    },
                    {
                        url: 'https://www.anjuke.com?twe=4',
                        title: '电脑版',
                        ref: '',
                        fromSoj: ''
                    },
                    {
                        url: download_url,
                        title: '客户端',
                        ref: 'nofollow',
                        fromSoj: ''
                    },
                    {
                        url: '/user-agreement/',
                        title: '免责声明',
                        ref: 'nofollow',
                        fromSoj: ''
                    },
                    {
                        url: 'http://m.anjuke.com/app-ad-static/contactus.html',
                        title: '联系我们',
                        ref: 'nofollow',
                        fromSoj: ''
                    }
                ];
            }

            return false;
        }
    },

    methods: {
        openWebMiniApp(){
            const baseUrl = 'weixin://dl/business/?appid=wx099e0647f9a4717d&path=page/tabbar/pages/home/home'
            const { query } = this.$route;
            let pi = query.pi;
            if(!isEmpty(pi)){
                if(typeof(pi) === Array){
                    pi = pi[0]
                }
                if(pi.includes('baidu-cpcyd-') || pi.includes('PZ-baidu-m')){
                    const paid = encodeURIComponent(`paid=${pi}&city_id=${this.city_id}`)
                    window.location.href = `${baseUrl}${`&query=${paid}`}`;
                }
            }
        },
        navItemClick(item, index, fromSoj) {
            let e = e || event;
            e.preventDefault();
            e.stopPropagation();
            let url = item.url;
            if (url.indexOf('?') !== -1) {
                location.href = url + '&from=' + fromSoj;
            } else {
                location.href = url + '?from=' + fromSoj;
            }
        },
        deepClone(obj) {
            if (_.isPlainObject(obj)) {
                return JSON.parse(JSON.stringify(obj));
            }
            return null;
        },
        mark(value) {
            const keyword = this.searchKey;
            if (value && _.isString(value) && keyword) {
                const key = new RegExp(keyword, 'ig');
                return value.replace(key, `<strong>${keyword}</strong>`);
            }
            return value;
        },
        printLog(level, args) {
            printLog(this.pagename, ...arguments);
        },
        //添加事件
        addEvent() {
            const win = $(window);
            const skw = $('.search-kuang-wrap');
            const skwC = $('.search-kuang');
            let bFg = -1;
            const topDown = $('.top-download');
            const _this = this;
            win.on({
                scroll() {
                    throttle(() => {
                        const st = $(this).scrollTop();
                        skwC[st >= skw.offset().top ? 'addClass' : 'removeClass']('fix-top');
                        if (st > $(this).height() && bFg < 0) {
                            _this.addBottomOpenAppExposure();
                            bFg = 1;
                        }
                        if (st <= $(this).height()) {
                            bFg = -1;
                        }
                        _this.showAppInOpen = st >= topDown.height();
                    }, 60)();
                }
            });
            $('#search-submit').on('submit', () => {
                if (this.searchKey) {
                    this.addSearchKeyHistory(this.searchKey);
                }
                return false;
            });
        },
        expendBtnClick(expendStatus) {
            this.$soj.trackEvent(expendStatus ? 'HOME_GUIDE_ZK' : 'HOME_GUIDE_GB');
        },
        addBottomOpenAppExposure() {
            // this.$soj.trackEvent('HOME_DOWNLOAD_FOOT_EXP', {"exposure": {"HOME_DOWNLOAD_FOOT_EXP": [1]}}, 3)
            this.$soj.trackEvent('HOME_DOWNLOAD_FOOT_EXP', { exposure: { HOME_DOWNLOAD_FOOT_EXP: [1] } }, 3);
        },
        themePackClick(item) {
            window.location.href = `${item.url}${item.url.indexOf('?') > -1 ? '&' : '?'}from=Exp_tw_home_theme_list`;
        },
        getClientData() {
            const city_id = this.city_id;
            // 猜你喜欢
            HomeAPI.getHomePropRecommend({ city_id })
                .then(data => {
                    if (_.isArray(data) && data.length) {
                        this.$set(this, 'guessLike', data);
                    }
                })
                .catch(error => {
                    this.printLog(1, '获取猜你失败', error);
                });

            // 获取热搜
            HomeAPI.getHomeHotCommunity({ city_id })
                .then(data => {
                    this.printLog(3, '获取二手房热门搜索成功', data);
                    if (_.isArray(data) && data.length) {
                        this.$set(this.searchHotTags, 'ESF', data.slice(0, 10));
                    }
                })
                .catch(error => {
                    this.printLog(1, '获取二手房热门搜索失败', error);
                });

            HomeAPI.getHomeXfHotSearch({ city_id })
                .then(data => {
                    this.printLog(3, '获取新房热门搜索成功', data);
                    if (_.isPlainObject(data)) {
                        this.$set(this.searchHotTags, 'XF', data);
                    }
                })
                .catch(error => {
                    this.printLog(1, '获取新房热门搜索失败', error);
                    if (_.isPlainObject(error) && _.get(error, 'result')) {
                        this.$set(this.searchHotTags, 'XF', error.result);
                    }
                });
        },

        setSearchPageView() {},
        searchInputFocus() {
            if (this.searchInput) {
                this.searchInput.focus();
            }
        },
        closeSearchPage() {
            this.isShowSearchPage = false;
            this.clearSearch();
        },
        clearSearch() {
            this.searchKey = '';
        },
        setSearchClassify() {
            const key = localStorage.getItem('searchClassifyKey');
            const keys = Object.keys(this.searchClassify);
            if (keys.indexOf(key) > -1) {
                this.searchClassifyKey = key;
            } else {
                this.searchClassifyKey = 'ESF';
                localStorage.setItem('searchClassifyKey', '');
            }
        },
        changeSearchClassify(classifyKey) {
            this.searchClassifyKey = classifyKey;
            localStorage.setItem('searchClassifyKey', classifyKey);
            this.isShowSearchClassify = false;
        },
        getSuggest() {
            this.clearSarchResult();
            this.abortSearchSuggestRequest();
            const newVal = this.searchKey;
            if (!newVal) {
                return false;
            }
            if (this.searchClassifyKey === 'ESF') {
                this.getEsfSuggest({
                    city_id: this.city_id,
                    kw: newVal,
                    classify: 'ESF'
                });
            } else if (this.searchClassifyKey === 'XF') {
                this.getXfSuggest({
                    city_id: this.city_id,
                    kw: newVal,
                    classify: 'XF'
                });
            }
        },
        getEsfSuggest({ kw, city_id, classify }) {
            this.searchSuggestRequest = $.ajax({
                url: '/ajax/autocomplete/',
                type: 'get',
                data: {
                    city_id,
                    kw
                },
                dataType: 'json',
                // ...extendOptions
                success: result => {
                    this.printLog(3, '二手房联想success:', result);
                    const match = _.get(result, 'data.match');
                    if (_.isArray(match) && match.length) {
                        this.searchResult.classify = classify;
                        this.$set(this.searchResult, 'list', match.slice(0, 20));
                    }
                },
                error: error => {
                    this.printLog(1, '二手房联想error:', error);
                }
            });
        },
        getXfSuggest({ kw, city_id, classify }) {
            this.searchSuggestRequest = $.ajax({
                //url:'http://easymock.fe.corp.anjuke.com/mock/5caab43c973dfd5ee0c630ed/home/hot/xf/search',
                url: '/home/hot/xf/search/',
                type: 'get',
                data: {
                    cid: city_id,
                    kw
                },
                dataType: 'json',
                // ...extendOptions
                success: result => {
                    this.printLog(3, '新房联想success:', result);
                    const loupan = _.get(result, 'data.loupan');
                    if (_.isArray(loupan) && loupan.length) {
                        this.searchResult.classify = classify;
                        const list = loupan.slice(0, 20);
                        list.forEach(x => {
                            if (!x.url) x.url = x.jump_url;
                        });
                        this.$set(this.searchResult, 'list', list);
                    }
                    const brand = _.get(result, 'data.brand');
                    if (_.isArray(brand) && brand.length) {
                        this.searchResult.classify = classify;
                        const list = brand.slice(0, 7);
                        list.forEach(x => {
                            if (!x.url) x.url = x.jump_url;
                        });
                        this.$set(this.searchResult, 'brand', list);
                    }
                },
                error: error => {
                    this.printLog(1, '新房联想error:', error);
                }
            });
        },
        abortSearchSuggestRequest() {
            if (this.searchSuggestRequest && this.searchSuggestRequest.abort) {
                this.searchSuggestRequest.abort();
                this.searchSuggestRequest = null;
            }
        },
        clearSarchResult() {
            this.searchResult.classify = '';
            this.$set(this.searchResult, 'list', null);
            this.$set(this.searchResult, 'brand', null);
        },

        selSearchHistory() {
            let storge = localStorage.getItem('searchHistory');
            try {
                storge = JSON.parse(storge);
                storge = _.isPlainObject(storge) ? storge : {};
            } catch (e) {
                storge = {};
            }
            this.$set(this, 'searchHistory', storge);
        },
        /**
         * 添加历史记录
         * searchHistory:{
         *    ESF:[
         *        {city_id:11,list:[{name:"xxx",url:"xxxx"}]},
         *        {city_id:22,list:[{name:"xxx",url:"xxxx"}]},
         *        {city_id:33,list:[{name:"xxx",url:"xxxx"}]},
         *    ]
         * }
         */
        addSearchHistory({ name, url, isSetHistory }) {
            const city_id = this.city_id;
            const searchClassifyKey = this.searchClassifyKey;
            let storge = localStorage.getItem('searchHistory');
            try {
                storge = JSON.parse(storge);
                storge = _.isPlainObject(storge) ? storge : {};
            } catch (e) {
                storge = {};
            }
            const cityArr = storge[searchClassifyKey];
            const newCurrentCity = {
                city_id,
                list: [{ name, url }]
            };
            if (cityArr && _.isArray(cityArr)) {
                const pos = { isIn: false, index: -1 };
                for (const k in cityArr) {
                    // 检查数据合法及数据是否存在
                    if (_.isPlainObject(cityArr[k]) && cityArr[k].city_id) {
                        if (cityArr[k].city_id === city_id) {
                            pos.isIn = true;
                            pos.index = k;
                            break;
                        }
                    } else {
                        delete cityArr[k];
                    }
                }
                if (pos.isIn) {
                    // 存在
                    const historyList = _.get(cityArr[pos.index], 'list');
                    if (_.isArray(historyList)) {
                        let isExist = -1;
                        _.forEach(historyList, (v, k) => {
                            if (v && v.name === name) {
                                isExist = k;
                                return false;
                            }
                        });
                        if (isExist > -1) {
                            historyList.splice(isExist, 1);
                        }
                        historyList.unshift({ name, url });

                        historyList.splice(10, historyList.length - 10);
                    } else {
                        // list 不合法
                        delete cityArr[pos.index];
                        cityArr.push(newCurrentCity);
                    }
                } else {
                    // 城市不存在
                    cityArr.push(newCurrentCity);
                    cityArr.splice(0, cityArr.length > 3 ? cityArr.length - 3 : 0);
                }
            } else {
                storge[searchClassifyKey] = [];
                storge[searchClassifyKey].push(newCurrentCity);
            }
            if (isSetHistory) {
                this.$set(this, 'searchHistory', storge);
            }
            localStorage.setItem('searchHistory', JSON.stringify(storge));
        },
        clearSearchHistory() {
            const city_id = this.city_id;
            const searchClassifyKey = this.searchClassifyKey;
            let storge = localStorage.getItem('searchHistory');
            try {
                storge = JSON.parse(storge);
                storge = _.isPlainObject(storge) ? storge : {};
            } catch (e) {
                storge = {};
            }
            const cityArr = storge[searchClassifyKey];
            if (cityArr && _.isArray(cityArr)) {
                let index = -1;
                for (const cak in cityArr) {
                    if (_.get(cityArr[cak], 'city_id') === city_id) {
                        // delete  cityArr[cak]
                        index = cak;
                        break;
                    }
                }
                if (index > -1) {
                    cityArr.splice(index, 1);
                }
            } else {
                storge[searchClassifyKey] = [];
            }

            this.$set(this, 'searchHistory', storge);
            localStorage.setItem('searchHistory', JSON.stringify(storge));
        },
        addSearchKeyHistory(name) {
            const searchClassifyKey = this.searchClassifyKey;
            let url = '';
            if (searchClassifyKey === 'ESF') {
                url = `/${this.city}/sale/?q=${name}`;
            } else if (searchClassifyKey === 'XF') {
                url = `/${this.city}/loupan/?q=${name}`;
                /* TODO 测试环境 */
                // url = 'http://m.fp05.kfs.dev.anjuke.test' + url
            }
            this.addSearchHistory({ name, url });
            location.href = url;
        },
        addSearchItemHistory(name, url, type, from) {
            if (type && type === 'ESF') {
                url += `${url.indexOf('?') > -1 ? '&' : '?'}q=${name}`;
            }
            if (from) {
                url += `${url.indexOf('?') > -1 ? '&' : '?'}from=${from}`;
            }
            let isSetHistory = true;
            if (url) {
                isSetHistory = false;
            }
            this.addSearchHistory({ name, url, isSetHistory });
            this.xfHistoryUrl(url)
        },
        xfHistoryUrl(url){
            if(url.includes('loupan')){
                // 新房跳转区分
                const loupanId = url.split('loupan/')[1].split('/')[0]
                HomeAPI.getXfUrl(loupanId).then(res => {
                    const url = res[0].ajk_aifang_action_url
                    location.href = url;
                }).catch(err => {
                    console.error(err)
                })
            }else{
                location.href = url;
            }
        },
        addSearchItemHistoryEsf(value) {
            try {
                let { comm_id, kw_type, url, keyword, from_code } = value;
                let urlStr = '';
                urlStr += keyword ? `q=${keyword}&` : '';
                urlStr += comm_id ? `k_comm_id=${comm_id}&` : '';
                urlStr += kw_type ? `kw_type=${kw_type}&` : '';
                urlStr += from_code ? `from=${from_code}&` : '';
                urlStr = urlStr.replace(/&$/gi, '');
                if (urlStr) {
                    url += (url.indexOf('?') > -1 ? '&' : '?') + urlStr;
                }
                this.addSearchItemHistory(keyword, url);
            } catch (e) {}
        },
        fetchAppTip() {
            this.$fetch({
                url: '/getAjkVersionInfoCross',
                transformResponse: [
                    data => {
                        return {
                            status: 'ok',
                            data,
                            msg: ''
                        };
                    }
                ]
            }).then(res => {
                this.tip = JSON.parse(res);
            });
        },
        onPrivateClick() {
            this.$jump('https://m.anjuke.com/policy/privacy?external=true', {}, true);
        },
        onPermissionClick() {
            this.isTipShow = true;
            ModalHelper.afterOpen();
        },
        onPriceClick(config) {
            const { jump_action } = config || {};
            this.$jump(jump_action, {}, true);
        },
        onProductClick(){
            this.$jump(this.ENV === 'anjuke' ? 'https://m.anjuke.com/feuser/richcontent/agreement?id=112' : 'https://static.58.com/git/_fe/ershoufang/static-pages/esf/common/index.html', {}, true)
        },
        onPermissionClose() {
            ModalHelper.beforeClose();
            this.isTipShow = false;
        }
        /* ...Object.keys(clientApi).map(function (value) {
            // return
        }) */
    }
};
