import { getStaticUrl } from './env';
import { loadScript } from '@/utils/common';

const script = getStaticUrl('/soj/perfect-soj-sdk-1.0.0-202008201800.js');
let sojList = [];

export const initTrack = (sojParams, trackLogParams) => {
    try {
        const sourceh5 = JSON.parse(document.body.getAttribute('data-sourceh5'));
        const cp = {
            ...(sojParams.cp || {}),
            sourceh5
        };
        loadScript(script, 'body', true, () => {
            const logger = new window.LoggerSDK.Logger({
                sojParams: {
                    p: sojParams.pageName,
                    pn: sojParams.pageName,
                    plat: sojParams.plat || 2,
                    site: location.origin.includes('.58.com') ? 'wuba' : 'anjuke',
                    cp
                },
                trackLogParams: {
                    url: trackLogParams.url
                }
            });
            window.logger = logger.getLogger();
            execute(() => {});
        });
    } catch (error) {
        console.log(error);
    }
};

export const exposure = (action, cp = {}) => {
    execute(() => {
        window.logger.send({
            action,
            cp,
            type: 3
        });
    });
};

export const track = (action, cp = {}) => {
    execute(() => {
        if (action) {
            window.logger.sendnpv({
                action,
                cp
            });
        } else {
            console.error('Please enter the click action.');
        }
    });
};

export const trackpv = (cp = {}, ep = {}) => {
    execute(() => {
        window.logger.sendpv({
            cp,
            ep
        });
    });
};

export const trackLego = url => {
    execute(() => {
        window.logger.sendlego(url);
    });
};

export const setPageName = pn => {
    execute(() => {
        window.logger.setDefault({
            p: pn,
            pn
        });
    });
};

export const mergeCustomParams = (cp = {}) => {
    execute(() => {
        window.logger.setDefault({
            cp
        });
    });
};

export const addExpNodes = dom => {
    execute(() => {
        window.logger.exposure.addNodes(dom);
    });
};

const execute = func => {
    if (window.logger) {
        if (sojList.length > 0) {
            sojList.forEach(itemFunc => {
                if (typeof itemFunc === 'function') {
                    itemFunc();
                }
            });
            sojList = [];
        }
        func();
    } else {
        sojList.push(func);
    }
};
